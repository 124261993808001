import React from "react";
import { Link } from "react-router-dom";

export default function Landing() {
    const buttonClasses = `sm:w-1/3 w-3/5 submit rounded sm:px-8 sm:py-4 px-4 py-2 mt-4 bg-info-light hover:bg-info focus:bg-info font-bold text-info-dark text-center`;
    return(
        <div className="w-screen h-scren flex flex-col m-auto justify-center items-center">
            <div className="w-full h-screen flex flex-col justify-center items-center">
                <h1 className="text-center text-info sm:text-2xl text-lg my-4 border-2 border-info-light rounded-lg font-bold py-2 sm:px-8 sm:w-auto w-4/5 px-4 tracking-wide">
                    Welcome to the Register/Login Demo App!
                </h1>
                <Link to="/register"className={buttonClasses}>Register</Link>
                <Link to="/login"className={buttonClasses}>Login</Link>
            </div>
        </div>
    );
}
