import React, { useState, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import {Context} from "../Context";

export default function Register() {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");

    const [isDataValid, setIsDataValid] = useState([false, false, false, false, false]);
    const [errorMessages, setErrorMessages] = useState(["", "", "", "", ""]);

    const [formOutput, setFormOutput] = useState("");

    const history = useHistory();
    const localContext = useContext(Context);
    const [loggedInUser] = useContext(Context).loginUser;

    const col = "blue";
    const inputClasses = `sm:w-1/3 w-4/5 border-b-2 border-yellow-300 border-dashed focus:border-${col}-500 bg-black text-${col}-500 placeholder-yellow-400 py-1 sm:text-xl text-lg mt-6 ${formOutput[0] === "F" ? "hidden" : ""}`;
    const infoTextClasses = `px-2 sm:w-1/3 w-4/5 sm:text-sm text-xs text-danger mt-1`;
    const buttonClasses = `submit rounded px-8 py-2 mt-4 bg-info-light hover:bg-info focus:bg-info font-bold text-info-dark ${formOutput[0] === "F" ? "hidden" : ""}`;

    function nameSetter(event) {
        setName(event.target.value);
        const check = /^[a-zA-Z ]+$/.test(event.target.value);
        updateDataValidity(check, 0);
        updateErrorMessages(check ? "" : "Invalid name entered", 0);
    }

    function userNameSetter(event) {
        setUsername(event.target.value);
        const check = /^[a-zA-Z0-9]+$/.test(event.target.value);
        const check2 = localContext.data[0].find(item => item.username === event.target.value) === undefined;
        
        updateDataValidity((check && check2), 1);

        if (!check) {
            updateErrorMessages("Invalid username entered", 1);
        } else if (!check2) {
            updateErrorMessages("Username already taken", 1);
        } else {
            updateErrorMessages("", 1);
        }
    }

    function emailSetter(event) {
        setEmail(event.target.value);
        // eslint-disable-next-line
        const check = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value);
        const check2 = localContext.data[0].find(item => item.email.toString() === event.target.value.toString());
        
        updateDataValidity((check && !check2), 2);

        if (!check) {
            updateErrorMessages("Invalid email address entered", 2);
        } else if (check2) {
            updateErrorMessages("Email address already registered", 2);
        } else {
            updateErrorMessages("", 2);
        }
    }

    function passwordSetter(event) {
        setPassword(event.target.value);
        const check = (event.target.value.length >= 8);
        // eslint-disable-next-line
        const lowercase = new RegExp("^(?=.*[a-z])");
        const lowercaseCheck = lowercase.test(event.target.value);
        // eslint-disable-next-line
        const uppercase = new RegExp("^(?=.*[A-Z])");
        const uppercaseCheck = uppercase.test(event.target.value);
        // eslint-disable-next-line
        const number = new RegExp("^(?=.*[0-9])");
        const numberCheck = number.test(event.target.value);
        // eslint-disable-next-line
        const symbol = new RegExp("^(?=.*[!@#\$%\^&\*])");
        const symbolCheck = symbol.test(event.target.value);

        updateDataValidity((check && lowercaseCheck && uppercaseCheck && numberCheck && symbolCheck), 3);

        //const newCheck = event.target.value === passwordCheck;
        //updateDataValidity(newCheck, 4);
        //updateErrorMessages(newCheck ? "" : "Passwords don't match", 4);

        if (!check) {
            updateErrorMessages("Password should be longer than 8 characters", 3)
        } else if (!lowercaseCheck) {
            updateErrorMessages("Password should contain at least 1 lowercase alphabetical character", 3);
        } else if (!uppercaseCheck) {
            updateErrorMessages("Password should contain at least 1 uppercase alphabetical character", 3);
        } else if (!numberCheck) {
            updateErrorMessages("Password should contain at least 1 numerical character", 3);
        } else if (!symbolCheck) {
            updateErrorMessages("Password should contain at least 1 symbol", 3);
        } else {
            updateErrorMessages("", 3);
        }
    }

    function passwordCheckSetter(event) {
        if (event) setPasswordCheck(event.target.value);
        const check = password === event.target.value;
        updateDataValidity(check, 4);
        updateErrorMessages(check ? "" : "Passwords don't match", 4);
    }

    function validateForm() {
        if (isDataValid.every(item => item)) {
            const data = {
                username: username,
                name: name,
                email: email,
                password: passwordCheck
            }
            
            axios.post('https://auth.kinesis.games/api/users/register/', data)
                 .then(res => console.log(res))
                 .catch(err => console.log(err))
                 .then(setTimeout(() => {
                            history.push('/login');
                       }, 3000));

            setFormOutput("Form validated! Please wait, you will be redirected shortly.");
        } else {
            setFormOutput("Please fill out the form properly and try again.");
        }
    }

    function updateDataValidity(validity, i) {
        const newData = isDataValid.map((item, index) => {
            if (index === i) {
                return validity;
            } else {
                return item;
            }
        })
        setIsDataValid(newData);
    }

    function updateErrorMessages(message, i) {
        const newData = errorMessages.map((item, index) => {
            if (index === i) {
                return message;
            } else {
                return item;
            }
        })
        setErrorMessages(newData);
    }

    if (loggedInUser.username) {
        setTimeout(() => {
            history.push('/');
        }, 1000);
        return(
            <div className="w-screen h-screen">
                <div className="flex flex-col justify-center items-center m-auto w-full h-full">
                    <p className="text-danger sm:text-3xl text-xl tracking-wide font-bold text-center px-4">
                        Please log out first to register as a new user.
                    </p>
                </div>
            </div>
        );
    }

    return(
        <div className="w-screen h-screen" onSubmit={() => validateForm()} onChange={() => setFormOutput("")}>
            <form className="flex flex-col mx-auto justify-center items-center h-full">
                <h1 className="sm:w-1/3 w-4/5 text-center text-info sm:text-6xl my-4 border-2 border-info-light rounded-lg text-2xl font-bold py-1 px-4 tracking-wide">
                    Register
                </h1>
                <input
                    type="text"
                    placeholder="Enter Name"
                    value={name}
                    onChange={e => nameSetter(e)}
                    className={inputClasses}
                />
                <p className={infoTextClasses}>{errorMessages[0]}</p>
                <input
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={e => userNameSetter(e)}
                    className={inputClasses}
                />
                <p className={infoTextClasses}>{errorMessages[1]}</p>
                <input
                    type="email"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={e => emailSetter(e)}
                    className={inputClasses}
                />
                <p className={infoTextClasses}>{errorMessages[2]}</p>
                <input
                    type="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={e => passwordSetter(e)}
                    className={inputClasses}
                />
                <p className={infoTextClasses}>{errorMessages[3]}</p>
                <input
                    type="password"
                    placeholder="Enter Password Again"
                    value={passwordCheck}
                    onChange={e => passwordCheckSetter(e)}
                    className={inputClasses}
                />
                <p className={infoTextClasses}>{errorMessages[4]}</p>
                <button className={buttonClasses}>Submit</button>
                <p className={`px-2 sm:w-1/3 w-4/5 mt-1 text-center ${formOutput[0] === "F" ? "text-success sm:text-2xl text-lg font-bold tracking-wide" : "text-danger sm:text-sm text-xs"}`}>{formOutput}</p>
            </form>
        </div>
    );
}
