import React from "react";
import { Switch, Route } from "react-router-dom";

import Landing from "./components/Landing";
import Register from "./components/Register";
import Login from "./components/Login";
import User from "./components/User";

export default function App() {
    return (
        <Switch>
            <Route exact path="/"><Landing /></Route>
            <Route exact path="/register"><Register /></Route>
            <Route exact path="/login"><Login /></Route>
            <Route exact path="/user"><User /></Route>
        </Switch>
    );
}
