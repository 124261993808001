import React, { useState, useEffect } from "react";
import axios from "axios";

const Context = React.createContext();

function ContextProvider(props) {
    const [data, setData] = useState({});
    const [loggedInUser, setLoggedInUser] = useState({});

    useEffect(() => {
        axios.get('https://auth.kinesis.games/api/users/')
             .then(res => setData(res.data))
             .catch(err => console.log(err));
    }, []);

    return(
        <Context.Provider value={{data: [data, setData], loginUser: [loggedInUser, setLoggedInUser]}}>
            {props.children}
        </Context.Provider>
    );
}

export { ContextProvider, Context };