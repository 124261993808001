import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {Context} from "../Context";

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [formOutput, setFormOutput] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const history = useHistory();
    const [loggedInUser, setLoggedInUser] = useContext(Context).loginUser;

    const col = "blue";
    const inputClasses = `sm:w-1/3 w-4/5 border-b-2 border-yellow-300 border-dashed focus:border-${col}-500 bg-black text-${col}-500 placeholder-yellow-400 py-1 sm:text-xl text-lg mt-6 ${formOutput[13] === "!" ? "hidden" : ""}`;
    const buttonClasses = `submit rounded px-8 py-2 mt-4 bg-info-light hover:bg-info focus:bg-info font-bold text-info-dark ${formOutput[13] === "!" ? "hidden" : ""}`;

    function userNameSetter(event) {
        setUsername(event.target.value);
        setFormOutput("");
    }

    function passwordSetter(event) {
        setPassword(event.target.value);
        setFormOutput("");
    }

    function validateForm() {
        setFormOutput("Auhenticating... Please wait");

        const data = {
            username: username,
            password: password
        }

        let error = "";

        axios.post('https://auth.kinesis.games/api/users/login/', data)
             .then(res => setLoggedInUser(
                {
                     id: res.data._id,
                     name: res.data.name,
                     username: res.data.username,
                     email: res.data.email,
                     password: password,
                }
             ))
             .catch(err => {console.log(err); error = err;})
             .then(() => {
                if (error) {
                    setFormOutput("Invalid credentials entered. Please try again.");
                } else {
                    setSubmitted(true);
                    setFormOutput("Authenticated! Logging in...");
                    setTimeout(() => {
                        history.push('/user');
                    }, 1500);
                }
             });
    }

    useEffect(() => {
        setSubmitted(false);
    }, []);
    
    if (loggedInUser.username && !submitted) {
        setTimeout(() => {
            history.push('/');
        }, 1000);
        return(
            <div className="w-screen h-screen">
                <div className="flex flex-col justify-center items-center m-auto w-full h-full">
                    <p className="text-danger sm:text-3xl text-xl tracking-wide font-bold text-center px-4">
                        Please log out first to login as a new user.
                    </p>
                </div>
            </div>
        );
    }

    return(
        <div className="w-screen h-screen" onSubmit={() => validateForm()}>
            <form className="flex flex-col mx-auto justify-center items-center h-full">
                <h1 className="sm:w-1/3 w-4/5 text-center text-info sm:text-6xl my-4 border-2 border-info-light rounded-lg text-2xl font-bold py-1 px-4 tracking-wide">
                    Login
                </h1>
                <input
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={e => userNameSetter(e)}
                    className={inputClasses}
                />
                <input
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={e => passwordSetter(e)}
                    className={inputClasses}
                />
                <button className={buttonClasses}>Submit</button>
                <p className={`px-2 sm:w-1/3 w-4/5 mt-1 text-center ${formOutput[13] === "!" ? "text-success sm:text-2xl text-lg font-bold tracking-wide" : "text-danger sm:text-sm text-xs"}`}>{formOutput}</p>
            </form>
        </div>
    );
}
