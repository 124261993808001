import React from "react";
import ReactDOM from "react-dom";

import { ContextProvider } from "./Context";
import { HashRouter as Router } from "react-router-dom";

import App from "./App";

ReactDOM.render(
    <Router>
        <ContextProvider>
            <App />
        </ContextProvider>
    </Router>,
    document.getElementById('root')
);
