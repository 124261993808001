import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { Context } from "../Context";

export default function User() {
    const history = useHistory();
    const [loggedInUser, setLoggedInUser] = useContext(Context).loginUser;

    const [name, setName] = useState(loggedInUser.name);
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");

    const [isDataValid, setIsDataValid] = useState([false, false, false, false]);
    const [errorMessages, setErrorMessages] = useState(["", "", "", ""]);

    const containerClasses = `border-2 sm:w-1/3 w-4/5 my-3 p-2 rounded-lg border-success-light flex flex-col items-center`;
    const labelTextClasses = `w-full w-4/5 sm:text-lg text-md text-warning mb-1 mono`;
    const inputClasses = `w-full border-b-2 border-yellow-300 border-dashed focus:border-blue-500 bg-black  placeholder-yellow-400 py-1 sm:text-xl text-lg`;
    const infoTextClasses = `w-full w-4/5 sm:text-sm text-xs text-danger mt-1`;
    const buttonClasses = `rounded px-8 py-2 mt-2 bg-info-light hover:bg-info focus:bg-info font-bold text-info-dark`;

    function nameSetter(event) {
        setName(event.target.value);
        const check = /^[a-zA-Z ]+$/.test(event.target.value);
        updateDataValidity(check, 0);
        updateErrorMessages(check ? "" : "Invalid name entered", 0);
    }

    function oldPasswordSetter(event) {
        setOldPassword(event.target.value);
        const check = (event.target.value.length >= 8);
        // eslint-disable-next-line
        const check2 = event.target.value === loggedInUser.password;
        // eslint-disable-next-line
        const lowercase = new RegExp("^(?=.*[a-z])");
        const lowercaseCheck = lowercase.test(event.target.value);
        // eslint-disable-next-line
        const uppercase = new RegExp("^(?=.*[A-Z])");
        const uppercaseCheck = uppercase.test(event.target.value);
        // eslint-disable-next-line
        const number = new RegExp("^(?=.*[0-9])");
        const numberCheck = number.test(event.target.value);
        // eslint-disable-next-line
        const symbol = new RegExp("^(?=.*[!@#\$%\^&\*])");
        const symbolCheck = symbol.test(event.target.value);

        //const newCheck = event.target.value === passwordCheck;
        //updateDataValidity(newCheck, 4);
        //updateErrorMessages(newCheck ? "" : "Passwords don't match", 4);

        updateDataValidity((check && check2 && lowercaseCheck && uppercaseCheck && numberCheck && symbolCheck), 1);

        if (!check) {
            updateErrorMessages("Password should be longer than 8 characters", 1);
        } else if (!lowercaseCheck) {
            updateErrorMessages("Password should contain at least 1 lowercase alphabetical character", 1);
        } else if (!uppercaseCheck) {
            updateErrorMessages("Password should contain at least 1 uppercase alphabetical character", 1);
        } else if (!numberCheck) {
            updateErrorMessages("Password should contain at least 1 numerical character", 1);
        } else if (!symbolCheck) {
            updateErrorMessages("Password should contain at least 1 symbol", 1);
        } else if (!check2) {
            updateErrorMessages("Password should correspond to the current password", 1);
        } else {
            updateErrorMessages("", 1);
        }
    }

    function passwordSetter(event) {
        setPassword(event.target.value);
        const check = (event.target.value.length >= 8);
        // eslint-disable-next-line
        const check2 = event.target.value === loggedInUser.password;
        // eslint-disable-next-line
        const lowercase = new RegExp("^(?=.*[a-z])");
        const lowercaseCheck = lowercase.test(event.target.value);
        // eslint-disable-next-line
        const uppercase = new RegExp("^(?=.*[A-Z])");
        const uppercaseCheck = uppercase.test(event.target.value);
        // eslint-disable-next-line
        const number = new RegExp("^(?=.*[0-9])");
        const numberCheck = number.test(event.target.value);
        // eslint-disable-next-line
        const symbol = new RegExp("^(?=.*[!@#\$%\^&\*])");
        const symbolCheck = symbol.test(event.target.value);

        //const newCheck = event.target.value === passwordCheck;
        //updateDataValidity(newCheck, 4);
        //updateErrorMessages(newCheck ? "" : "Passwords don't match", 4);

        updateDataValidity((check && check2 && lowercaseCheck && uppercaseCheck && numberCheck && symbolCheck), 2);

        if (!check) {
            updateErrorMessages("Password should be longer than 8 characters", 2);
        } else if (!lowercaseCheck) {
            updateErrorMessages("Password should contain at least 1 lowercase alphabetical character", 2);
        } else if (!uppercaseCheck) {
            updateErrorMessages("Password should contain at least 1 uppercase alphabetical character", 2);
        } else if (!numberCheck) {
            updateErrorMessages("Password should contain at least 1 numerical character", 2);
        } else if (!symbolCheck) {
            updateErrorMessages("Password should contain at least 1 symbol", 2);
        } else if (!check2) {
            updateErrorMessages("Password should correspond to the current password", 2);
        } else {
            updateErrorMessages("", 2);
        }

        const newCheck = event.target.value === passwordCheck;
        updateDataValidity(newCheck, 3);
        updateErrorMessages(newCheck ? "" : "Passwords don't match", 3);
    }

    function passwordCheckSetter(event) {
        if (event) setPasswordCheck(event.target.value);
        const check = password === event.target.value;
        updateDataValidity(check, 3);
        updateErrorMessages(check ? "" : "Passwords don't match", 3);
    }

    function updateDataValidity(validity, i) {
        const newData = isDataValid.map((item, index) => {
            if (index === i) {
                return validity;
            } else {
                return item;
            }
        })
        setIsDataValid(newData);
    }

    function updateErrorMessages(message, i) {
        const newData = errorMessages.map((item, index) => {
            if (index === i) {
                return message;
            } else {
                return item;
            }
        })
        setErrorMessages(newData);
    }

    function submitName() {
        const data = {
            id: loggedInUser.id,
            name: name,
            username: loggedInUser.username,
            email: loggedInUser.email,
            old_password: loggedInUser.password,
            password: loggedInUser.password,
        };

        axios.post('https://auth.kinesis.games/api/users/update/', data)
             .then(res => console.log(res))
             .catch(err => console.log(err))
             .then(setLoggedInUser(data));
    }

    function submitPassword() {
        const data = {
            id: loggedInUser.id,
            name: name,
            username: loggedInUser.username,
            email: loggedInUser.email,
            old_password: oldPassword,
            password: password,
        };

        axios.post('https://auth.kinesis.games/api/users/update/', data)
             .then(res => console.log(res))
             .catch(err => console.log(err))
             .then(setLoggedInUser(data))
             .then(setOldPassword(""))
             .then(setPassword(""))
             .then(setPasswordCheck(""));
    }

    function logout() {
        setLoggedInUser(
            {
                id: "",
                name: "",
                username: "",
                email: "",
                old_password: "",
                password: "",
            }
        );
        history.push('/');
    }

    if (!loggedInUser.username) {
        setTimeout(() => {
            history.push('/');
        }, 1000);
        return(
            <div className="w-screen h-screen">
                <div className="flex flex-col justify-center items-center m-auto w-full h-full">
                    <p className="text-danger sm:text-3xl text-xl tracking-wide font-bold">
                        Please login first.
                    </p>
                </div>
            </div>
        );
    }

    return(
        <div className="w-screen h-screen">
            <div className="flex flex-col justify-center items-center m-auto w-full h-full">
                <h1 className="text-info tracking-wider sm:text-4xl text-2xl font-bold mb-4">Welcome, {loggedInUser.name.split(" ")[0]} !</h1>
                <div className={containerClasses}>
                    <p className={labelTextClasses}>Name</p>
                    <input
                        type="text"
                        placeholder="Enter Updated Name"
                        value={name}
                        onChange={e => nameSetter(e)}
                        className={`${inputClasses} text-blue-500`}
                    />
                    <p className={infoTextClasses}>{errorMessages[0]}</p>
                    { errorMessages[0] || (name === loggedInUser.name) ? '' : <button className={buttonClasses} onClick={() => submitName()}>Change Name</button> }
                </div>
                <div className={containerClasses}>
                    <p className={labelTextClasses}>Username</p>
                    <input
                        disabled
                        type="text"
                        value={loggedInUser.username}
                        className={`${inputClasses} text-red-500`}
                    />
                </div>
                <div className={containerClasses}>
                    <p className={labelTextClasses}>Email Address</p>
                    <input
                        disabled
                        type="email"
                        value={loggedInUser.email}
                        className={`${inputClasses} text-red-500`}
                    />
                </div>
                <div className={containerClasses}>
                    <p className={labelTextClasses}>Password</p>
                    <input
                        type="password"
                        placeholder="Enter Current Password"
                        value={oldPassword}
                        onChange={e => oldPasswordSetter(e)}
                        className={`${inputClasses} text-blue-500`}
                    />
                    <p className={infoTextClasses}>{errorMessages[1]}</p>
                    <input
                        type="password"
                        placeholder="Enter New Password"
                        value={password}
                        onChange={e => passwordSetter(e)}
                        className={`${inputClasses} text-blue-500`}
                    />
                    <p className={infoTextClasses}>{errorMessages[2]}</p>
                    <input
                        type="password"
                        placeholder="Enter New Password Again"
                        value={passwordCheck}
                        onChange={e => passwordCheckSetter(e)}
                        className={`${inputClasses} text-blue-500`}
                    />
                    <p className={infoTextClasses}>{errorMessages[3]}</p>
                    { errorMessages[1] || errorMessages[2] || errorMessages[3] || !oldPassword || !password || !passwordCheck ? '' : <button className={buttonClasses} onClick={() => submitPassword()}>Change Password</button> }
                </div>
                <div className={containerClasses}>
                    <p className="text-info-light sm:text-sm text-xs">User ID: {loggedInUser.id}</p>
                </div>
                <button className={buttonClasses} onClick={() => logout()}>Logout</button>
            </div>
        </div>
    );
}
